import { LandingOnlyChangeModal } from '../../Interfaces/ComponentsInterfaces/LandingPageComponents'
import '../../Modals/LandingModals/LandingModalsCSS.css'

function HomeModal({ setCurrentModal }: LandingOnlyChangeModal) {
	return (
		<div className="HomeModalMainDiv">
			<div className='HomeModalTextDiv'>
				<div className='HomeInternalDiv'>
					<h1>LinkClass:</h1>
					<h1>Comunicación efectiva:</h1>
					<h4>LinkClass es una aplicación que permite a los docentes actuar rápidamente en situaciones críticas,
						facilitando la comunicación instantánea y efectiva para ayudar tanto a estudiantes como a
						profesionales dentro de la comunidad escolar. </h4>
					<button type='button' onClick={() => setCurrentModal('WhatIs')}>
						Ver más
					</button>
				</div>
			</div>
		</div>
	)
}

export default HomeModal