import AboutUs from "../../../Components/LandingPageComponents/AboutUs"
import OurAwards from "../../../Components/LandingPageComponents/OurAwards"
import OurBelieves from "../../../Components/LandingPageComponents/OurBelieves"

function AboutUsModal() {
	return (
		<>
			<AboutUs />
			<OurAwards 
				componentPosition='AboutUs'
			/>
			<OurBelieves />
		</>
	)
}

export default AboutUsModal