import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./Others/NavBar/NavBar";
import LandingPage from "./Views/LandingPage/LandingPage";
import AdministratorPage from "./Views/AdministratorPage/AdministratorPage";
import SchoolPage from "./Views/SchoolPage/SchoolPage";
import SchoolAdministratorPage from "./Views/SchoolAdministratorPage/SchoolAdministratorPage";
import UserPage from "./Views/UserPage/UserPage";
import GroupPage from "./Views/GroupPage/GroupPage";
import Footer from "./Others/Footer/Footer";

import "./index.css";

function Routing() {
	const location = useLocation();

	return (
		<>
			<div className="PagesContainers">
				<Routes>
					<Route path="/*" element={<LandingPage />} />
				</Routes>
			</div>
		</>
	);
}

export default Routing;
