import ScrollLink from "./ScrollLink";
import { useNavigate } from "react-router-dom";
import { LandingOnlyChangeModal } from "../../../Interfaces/ComponentsInterfaces/LandingPageComponents";
import '../../../Views/LandingPage/LandingPage.css'

function Nav({ setCurrentModal }: LandingOnlyChangeModal) {

	const navigate = useNavigate()

	const handleLogin = () => {
		navigate('/login')
	}

	return (
		<>
			<div className="nav-links">
				<div className="landing-basic-buttons">
					<button type='button' onClick={() => setCurrentModal('WhatIs')}>
						¿Qué es LinkClass?
					</button>
					<button type='button' onClick={() => setCurrentModal('AboutUs')}>
						Sobre nosotros
					</button>
				</div>
				<div className="nav-links-buttons">
					<button type='button' onClick={handleLogin}>
						Ingresar
					</button>
					<button type='button' className="contact-button">
						<ScrollLink to="contact">
							Contáctanos
						</ScrollLink>
					</button>
				</div>
			</div>
		</>
	);
}

export default Nav;
