import { UserInterface } from "./User";
import { GroupInterface } from "./Group";
import { NoticeInterface } from "./Notice";
import { SchoolInterface } from "./School";
import { StudentInterface } from "./Student";
import { GroupOfUserInterface } from "./GroupOfUser";

const DefaultUser: UserInterface = {
	name: process.env.REACT_APP_USER_MOCK_NAME || 'defaultName',
	position: process.env.REACT_APP_USER_MOCK_POSITION || 'defaultPosition',
	email: process.env.REACT_APP_USER_MOCK_EMAIL || 'defaultMail',
	SK: process.env.REACT_APP_USER_MOCK_SK || 'defaultSK',
	notification_token: process.env.REACT_APP_USER_MOCK_NOTIFICATION_TOKEN || '',
	off_mode_until: process.env.REACT_APP_USER_MOCK_OFF_MODE_UNTIL || '',
	defaults: []
}

const DefaultGroup: GroupInterface = {
	name: 'DefaultName',
	PK: 'DefaultPk',
	SK: 'DefaultSk',
	students: []
}

const DefaultNotice: NoticeInterface = {
	type: 'DefaultType',
	position: DefaultUser.position,
	groups: [],
	description: 'DefaultText',
	location: '',
	user_id: DefaultUser.SK,
	notify: false
}

const DefaultSchool: SchoolInterface = {
	name: 'DefaultSchool',
	address: 'DefaultAddress',
	SK: process.env.REACT_APP_DEFAULT_SCHOOL_ID || 'DefaultSK',
	types: [],
	templates: {},
	logoUrl: undefined
}

const DefaultStudent: StudentInterface = {
	rut: 'DefaultRut',
	name: 'DefaultName'
}

const DefaultPriorities: string[] = [
	'low', 'mid', 'high', 'always'
]

const DefaultGroupOfStudent : GroupOfUserInterface = {
	group_id : '0',
	label: '',
	priorities: {}
}

export { DefaultUser, DefaultGroup, DefaultNotice, DefaultSchool, DefaultStudent, DefaultPriorities, 
	DefaultGroupOfStudent };