import { teamData } from "./Data/teamData"
import IndividualTeamMemberData from "./IndividualTeamMemberData"
import '../../Modals/LandingModals/LandingModalsCSS.css'

function AboutUs() {

	const teamInformation = teamData

	return (
		<div className="AboutUsMainDiv">
			<div className="AboutUsTitleDiv">
				<h1>Sobre Nosotros...</h1>
				<h4>
					En LinkClass, estamos comprometidos con apoyar el trabajo de los profesionales de la educación
					y buscamos alinear comunidades educativas con un objetivo común: El bienestar de los estudiantes.
				</h4>
			</div>
			<div className="TeamMemberMapDiv">
				{teamInformation.map((teamMember) => (
					<IndividualTeamMemberData
						teamMember={teamMember}
					/>
				))}
			</div>
		</div>
	)
}

export default AboutUs