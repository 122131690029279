import BenefitCalm from "../../Assets/BenefitCalm.png";
import BenefitResponse from "../../Assets/BenefitResponse.png";
import BenefitComunication from "../../Assets/BenefitComunication.png";
import { LandingOnlyChangeModal } from "../../Interfaces/ComponentsInterfaces/LandingPageComponents";
import '../../Modals/LandingModals/LandingModalsCSS.css'

function BenefitsSection({ setCurrentModal }: LandingOnlyChangeModal) {
	return (
		<section id="benefits" className="benefits-section">
			<div className="benefits-section-main-div">
				<h1>¿Por qué Link Class?</h1>
				<div className="cards-container">
					<div className="SingularBelieveDiv">
						<img src={BenefitCalm} alt="target" />
						<h2>Reduce el estrés</h2>
						<h4>
							Alivia el agobio docente y reduce un 36% el trabajo de jefatura, asignando responsabilidades
							de forma clara para una gestión educativa más eficiente.
						</h4>
					</div>
					<div className="SingularBelieveDiv">
						<img src={BenefitResponse} alt="analysis" />
						<h2>
							Mejora la respuesta
						</h2>
						<h4>
							Además de asegurar una comunicación rápida y eficiente, te ayudamos a tomar decisiones informadas
							y anticiparte a futuras situaciones críticas.
						</h4>
					</div>
					<div className="SingularBelieveDiv">
						<img src={BenefitComunication} alt="education" />
						<h2>Organiza la comunicación</h2>
						<h4>
							Maneja toda la comunicación escolar dentro de una sola plataforma: Intuitiva, fácil de usar y
							diseñada para facilitar tu trabajo diario.
						</h4>
					</div>
				</div>
				<button onClick={() => setCurrentModal('WhatIs')}>
					Ver más
				</button>
			</div>
		</section>
	);
};

export default BenefitsSection;
