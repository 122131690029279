import '../../Modals/LandingModals/LandingModalsCSS.css'
import AboutUsLogoPicture from '../../Assets/StartUpLogo.png'
import homePicture from '../../Assets/StartUpLogoHome.png'
import { OurAwardInterface } from '../../Interfaces/ComponentsInterfaces/LandingPageComponents'

function OurAwards( { componentPosition } : OurAwardInterface) {
	return(
		<div className={componentPosition === 'AboutUs' ? "OurAwardsAboutUsMainDiv": 'OurAwardsHomeMainDiv'}>
			<h1>Fuimos seleccionados por</h1>
			<img src={componentPosition === 'AboutUs' ? AboutUsLogoPicture: homePicture} alt="StartUpLogo"/>
			<h1>Como uno de los startups ganadores en su programa Build de la generación BIG7</h1>
		</div>
	)
}
export default OurAwards