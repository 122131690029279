import profile1 from '../../../Assets/OwnerProfile1.png'
import profile2 from '../../../Assets/OwnerProfile2.png'
import profile3 from '../../../Assets/OwnerProfile3.png'

export interface teamMember {
	pictureURL: string,
	name: string,
	job: string,
	info: string
}

export const teamData: teamMember[] = [
	{
		pictureURL: profile1,
		name: 'María Fernanda Freitte',
		job: 'Profesora',
		info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod" +
			"tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation" +
			"ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in" +
			"voluptate velit esse cillum dolore eu fugiat nulla pariatur."
	},
	{
		pictureURL: profile3,
		name: 'Sofía Vicuña',
		job: 'Profesora',
		info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod" +
			"tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation" +
			"ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in" +
			"voluptate velit esse cillum dolore eu fugiat nulla pariatur."
	},
	{
		pictureURL: profile2,
		name: 'Santiago Laguna',
		job: 'Desarrollador',
		info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod" +
			"tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation" +
			"ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in" +
			"voluptate velit esse cillum dolore eu fugiat nulla pariatur."
	}
]