import BelieveCommunity from '../../Assets/BelieveCommunitiy.png'
import BelieveEmpathy from '../../Assets/BelieveEmpathy.png'
import BelieveProfesionalims from '../../Assets/BelieveProfesionalims.png'
import '../../Modals/LandingModals/LandingModalsCSS.css'

function OurBelieves() {
	return (
		<div className='OurBelievesMainDiv'>
			<div className='OurBelieveTitleDiv'>
				<h1>Creemos en...</h1>
			</div>
			<div className='OurBelievesMap'>
				<div className='SingularBelieveDiv'>
					<img src={BelieveCommunity} alt='Comunity Logo' />
					<h2>Comunidad</h2>
					<h4>
						Creemos que una buena comunicación es fundamental para el bienestar y crecimiento de la
						comunidad educativa, donde día a día enfrentan situaciones de gran estrés y sensibilidad.
					</h4>
				</div>
				<div className='SingularBelieveDiv'>
					<img src={BelieveEmpathy} alt='Emphaty Logo' />
					<h2>Empatía</h2>
					<h4>
						Trabajamos de la mano con profesores y otros profesionales de la comunidad educativa, escuchando
						sus experiencias para crear una solución integral y completa.
					</h4>
				</div>
				<div className='SingularBelieveDiv'>
					<img src={BelieveProfesionalims} alt='Profesionalism Logo' />
					<h2>Profesionalismo</h2>
					<h4>
						Creemos que mantener una separación saludable entre lo laboral y lo personal es esencial para
						la vida de las personas, ayudando a su salud mental y desempeño profesional.
					</h4>
				</div>
			</div>
		</div>
	)
}

export default OurBelieves