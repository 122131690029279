import heroImg from "../../Assets/linkclass-hero.png"
import '../../Modals/LandingModals/LandingModalsCSS.css'

const HeroSection = () => {
	return (
		<section id="hero" className="hero-section-main-div">
			<div className="hero-section-sub-div">
				<div className="hero-background">
					<div className="hero-circle"></div>
					<img src={heroImg} alt="hero" className="hero-image" />
				</div>
				<div className="hero-content">
					<h1>
						¿Sabías que...
					</h1>
					<h4>
						<strong>un profesor toma alrededor de 20 decisiones en tan solo 90 segundos al enfrentarse a una
							situación crítica?</strong> En promedio, los colegios enfrentan 35 situaciones críticas al día.
					</h4>
					<h4>
						En LinkClass trabajamos contigo para ayudarte con esas decisiones para que puedas responder de forma
						rápida y eficiente frente a estas situaciones.
					</h4>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
