import { Link } from "react-scroll";

import { ReactNode } from "react";

import React from "react";

interface ScrollLinkProps {
    to: string;
    children: ReactNode;
}

const ScrollLink : React.FC<ScrollLinkProps> = ({ to, children }) => {
	return (
		<Link
			to={to}
			spy={true}
			smooth={true}
			duration={800}
			offset={-70}
		>
			{children}
		</Link>
	);
};

export default ScrollLink;
