import { LandingOnlyChangeModal } from "../../../Interfaces/ComponentsInterfaces/LandingPageComponents"
import NavBarLogo from "./NavBarLogo"
import Nav from "./Nav"
import '../../../Views/LandingPage/LandingPage.css'

function LandingNavBar({ setCurrentModal }: LandingOnlyChangeModal) {
	return (
		<div className="landing-navbar">
			<NavBarLogo setCurrentModal={setCurrentModal} />
			<Nav setCurrentModal={setCurrentModal} />
		</div>
	)
}

export default LandingNavBar