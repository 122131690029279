import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Routing from './Routing';
import "./index.css";
import GeneralProvider from './Contexts/GeneralContext/GeneralProvider';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<GeneralProvider>
			<BrowserRouter>
				<div className='AppContainter'>
					<Routing />
				</div>
			</BrowserRouter>
		</GeneralProvider>
	</React.StrictMode>
);

reportWebVitals();
