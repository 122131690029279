import { IndividualTeamMemberInterface } from '../../Interfaces/ComponentsInterfaces/LandingPageComponents'
import '../../Modals/LandingModals/LandingModalsCSS.css'


function IndividualTeamMemberData({ teamMember }: IndividualTeamMemberInterface) {
	return (
		<div className='IndividualTeamMemberMainDiv'>
			<img src={teamMember.pictureURL.trim() !== '' ? teamMember.pictureURL : '/logo.png'} alt='MemberPicture'/>
			<h1>{teamMember.name}</h1>
			<h2>{teamMember.job}</h2>
			<h4>{teamMember.info}</h4>
		</div>
	)
}

export default IndividualTeamMemberData