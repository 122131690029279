import { ReactNode, useContext, useState } from "react";
import { UserInterface } from "../../Interfaces/User";
import { SchoolInterface } from "../../Interfaces/School";
import { GroupOfUserInterface } from "../../Interfaces/GroupOfUser";
import { DefaultSchool, DefaultUser } from "../../Interfaces/DefaultEntities";
import GeneralContext from "./GeneralContext";
import axios from "axios";


const GeneralProvider = ({ children }: { children: ReactNode }) => {

	const [user, setUser] = useState<UserInterface>(DefaultUser) // My user
	const [userToWatch, setUserToWatch] = useState<UserInterface>(DefaultUser) // user that i may watch
	const [hasLogged, setHasLogged] = useState<boolean>(false)
	const [groupsOfUser, setGroupsOfUser] = useState<GroupOfUserInterface[]>([])
	const [schoolOfUser, setSchoolOfUser] = useState<SchoolInterface>(DefaultSchool)

	const updateGroupsOfUser = async () => {
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/` + 
				`${userToWatch.SK}?school_id=${schoolOfUser.SK}`
			const response = await axios.get(url)
			setGroupsOfUser(response.data)
		} catch (error) { }
	}


	return (
		<GeneralContext.Provider value={{
			user, setUser, hasLogged, setHasLogged, userToWatch, setUserToWatch,
			groupsOfUser, setGroupsOfUser,
			schoolOfUser, setSchoolOfUser, updateGroupsOfUser
		}}>
			{children}
		</GeneralContext.Provider>
	)
}

function useGeneralContext() {
	const context = useContext(GeneralContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context;
}

export { useGeneralContext }
export default GeneralProvider;